// COMMON DEFAULTS
export const onboardingRoute = "/onboarding";
export const authRoute = "/auth";
export const welcomeRoute = "/welcome";
export const publicURLs = ["/404", "/email"] as const;

// ROLE SPECIFIC ROUTES
const commonRoutes = ["/privacy-policy", "/terms-and-conditions", "/all-methodologies", welcomeRoute] as const;
export const projectDeveloperRouteRoots = ["/project-developer", ...commonRoutes] as const;
export const reviewerRouteRoots = ["/reviewer", ...commonRoutes] as const;
export const adminRouteRoots = ["/admin", ...commonRoutes] as const;

// ROLE SPECIFIC DEFAULT ROUTES
export const projectDeveloperDefaultRoute = "/project-developer/methodologies/my-methodologies";
export const reviewerDefaultRoute = "/reviewer/methodologies";
export const adminDefaultRoute = "/admin/tools";
